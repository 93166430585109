<template>
    <div v-show="imports.length" class="item-progress-wrapper">
        <!--<div class="import-items-progress-title">Importações em andamento</div>
        <div 
            v-for="(data) in imports"
            :key="data.id"
        >
            <div class="d-flex">
                <b-progress
                    :max="100"
                    height="1.3rem"
                    :animated="!data.finished_at"
                    class="w-100"
                    >
                    <b-progress-bar   
                        class="progress-bar"                     
                        :value="importProgress(data)"
                        :variant="progressBarColor(data)"
                    />
                        <div class="file-name">{{ data.file_name }}</div>
                    </b-progress>
                    <span
                        class="progress-label ml-2"
                        :class="`text-${progressBarColor(data)}`"
                    >
                        {{ importProgress(data) }}%
                    </span>
            </div>
        </div>-->
    </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    props: {
        type: String
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            imports: [],
        }
    },
    async created() {
        this.imports = []
        const channel = this.pusher.subscribe(`import-items-${this.clinic.id}`)
        channel.bind('updatedInProgress', this.updateImportProgress, this)
        this.getPendingImports()
    },
    methods: {
        progressBarColor(data) {
            if (data.errors_count === data.processed_rows_count) return 'danger'
            if (data.errors_count > 0) return 'warning'
            return 'success'
        },
        importProgress(data) {
            if (data.processed_rows_count >= data.total_rows_count) return 100
            return data?.total_rows_count > 0 ? parseInt((data.processed_rows_count / data.total_rows_count) * 100) : 0
        },
        updateImportProgress(data) {
            if (data.type !== this.type) return

            const imports = [...this.imports]
            const tableImportIndex = imports.findIndex(el => el.id === data.id)

            const foundData = imports.find(el => el.id === data.id)

            if (!foundData) 
                imports.push(data)
            
            if (data.processed_rows_count === data.total_rows_count)
                this.$emit('reload')
            
            imports.splice(tableImportIndex, 1, data)
            this.imports = imports
        },
        async getPendingImports() {
            try {
                const { data } = await this.api.getImportsByClinicIdAndTypes(this.clinic.id, ['ITEM'])
                this.imports = data.filter(el => !el.finished_at)
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            } catch (error) {
                this.$toast.error(error.message)
            }
        },
    },
    
}
</script>

<style lang="scss" scoped>
.item-progress-wrapper {
    margin: 1rem 0;
    border: 1px dashed var(--neutral-200);
    border-radius: 4px;
    padding: 1rem;
}
.import-items-progress-title {
    margin-bottom: 4px;
}
.progress-bar {
    position: relative;
}
.file-name {
    position: absolute;
    font-size: 11px;
    padding-top: 10px;
    padding-left: 10px;
    color: white;
}
</style>